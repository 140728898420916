<script>
	import Container from '$lib/Container.svelte';
	import ContentBody from '$lib/ContentBody.svelte';
	import Header from '$lib/core/Header.svelte';

	import ArrowTopRightIcon from 'svelte-material-icons/ArrowTopRight.svelte';

	import { goto } from '$app/navigation';
	import HomePage from '$lib/pages/HomePage.svelte';
	import { userProfile } from '$lib/userProfileStore';
	import AthleteSignUpPage from '$lib/pages/AthleteSignUpPage.svelte';
</script>

{#if $userProfile?.type === 1}
	<HomePage />
{:else}
	<AthleteSignUpPage
		isHome={ true } />
{/if}
