<script>
	import { page } from '$app/stores';
	import Button from '$lib/Button.svelte';
	import Container from '$lib/Container.svelte';
	import ContentBody from '$lib/ContentBody.svelte';
	import Avatar from '$lib/core/Avatar.svelte';
	import Chip from '$lib/core/Chip.svelte';
	import Header from '$lib/core/Header.svelte';
	import Section from '$lib/core/Section.svelte';
	import SectionContainer from '$lib/core/SectionContainer.svelte';
	import ProfilePrograms from '$lib/profile/ProfilePrograms.svelte';
	import ProfileHeader from '$lib/profile/ProfileHeader.svelte';
	import { fetchUser } from '$lib/userAPI';
	import { onMount } from 'svelte';
	import EditSchedule from '$lib/EditSchedule.svelte';
	import ProfileSummarySection from '$lib/profile/ProfileSummarySection.svelte';
	import RippleIcon from '$lib/icons/RippleIcon.svelte';
	import CalendarIcon from 'svelte-material-icons/CalendarClockOutline.svelte';
	import TrainingSummarySection from '$lib/profile/TrainingSummarySection.svelte';
	import { userProfile } from '$lib/userProfileStore';
	import { goto } from '$app/navigation';

	let isNotFound = false;
	let isLoading = true;
	
	goto("/athletes")

	onMount(async () => {
		// isLoading = false;
	});
</script>

<ContentBody {isLoading} {isNotFound}>
	<Container>
		<SectionContainer>
			<div style="padding:30px 0 30px;font-size:20px;font-weight:600;">
				Welcome back, {$userProfile?.fName}
				{$userProfile?.lName}
			</div>

			<div class="flex gap-15">
				<div style="max-width:800px;">
					<SectionContainer>
						<div class="flex gap-15">
							<Section style="border-radius:20px;">
								<div style="margin-bottom: 20px;">
									<CalendarIcon size="28" color="gray" />
								</div>
								<div style="font-size:32px;font-weight:700;">2</div>
								<div class="text-semibold" style="margin-top:15px;">Ready for Next Week</div>
							</Section>

							<Section style="border-radius:20px;">
								<div style="margin-bottom: 20px;">
									<CalendarIcon size="28" color="gray" />
								</div>
								<div style="font-size:32px;font-weight:700;">--</div>
								<div class="text-semibold" style="margin-top:15px;">Check ins</div>
							</Section>

							<Section style="border-radius:20px;">
								<div style="margin-bottom: 20px;">
									<CalendarIcon size="28" color="gray" />
								</div>
								<div style="font-size:32px;font-weight:700;">--</div>
								<div class="text-semibold" style="margin-top:15px;"></div>
							</Section>
						</div>


						<Section title="Clients">
							<div>
								<div>
									Subscriptions
								</div>
								<div>
									One Time
								</div>
							</div>
						</Section>
					</SectionContainer>
				</div>
			</div>
		</SectionContainer>
	</Container>
</ContentBody>
